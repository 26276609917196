
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {},
})
export default class Home extends Vue {

  public image = 1;
  public total = 34;
  get imageurl () {
    return `fotos/${this.image}.jpg`;
  }
  public setImage(image: number) {
    this.image = image;
  }
  public next () {
    this.image++;
    if (this.image > this.total) {
      this.image = 1;
    }
  }

  public prev () {
    this.image--;
    if (this.image < 1) {
      this.image = this.total;
    }
  }
}


import router from "@/router";
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {},
})
export default class App extends Vue {

  public isHome = 'navmobhome';

  public path = ""

  public show = false;

  public mounted(){
    this.isHome = window.location.pathname == '/' ? 'navmobhome' : 'navmob';
    this.path = window.location.pathname;
  }

  get pathUrl(){
    return this.path;
  }

  get showHome(){
    return (this.show) ? 'showHome' : '';
  }

  get classHome(){
    return this.isHome;
  }

}

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/instituicao',
    name: 'Instituicao',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/como-doar',
    name: 'como doar',
    component: () => import(/* webpackChunkName: "about" */ '../views/comodoar.vue')
  },
  {
    path: '/historia',
    name: 'historia',
    component: () => import(/* webpackChunkName: "about" */ '../views/historia.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
